.main-frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    padding: 0px;
    

    width: 200px;
    height: 800px;


    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.sidebar {
    margin-top: 0px;
    height: 100vh;
    margin-right: 0px;
  }