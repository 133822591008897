.main-frame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 50px;
    gap: 10px;

    /* width: 1201px;
    height: 752px; */

    background: #FFFFFF;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.form {
    margin-top: 50px;
    width: 500px;
}

.spin {
    margin-top: 50px;
    width: 500px;
}