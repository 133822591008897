.person-form {
    margin-top: 50px;
    padding-right: 50px;
    width: 100%;
}

.person-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 20px;
}