.main-frame {
    /* display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px; 
    
    position: relative; */
    width: 100%;
    height: 100%;


    padding: 16px 16px;

    background: #FFFFFF;

}



.container {
    display: flex;
    justify-content: space-between;
}


.selected-row {
    background-color: rgb(213, 231, 249);
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: rgb(237, 243, 251);
}


.title {
    width: '100%';
    text-align: 'center';
    background-color: rgb(255, 255, 255);
    font-weight: 700;
}

.persons-table {
    margin-top: 20px;
}